import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
    flow,
    getRoot,
    getEnv,
} from 'mobx-state-tree';
import { generatePath } from 'react-router-dom';

import { storageKeys } from 'src/shared/constants';
import { PurchaseListDto } from 'src/shared/types';
import { AppRoutes } from 'src/routing/appRoutes';

import {
    BookingPurchaseItem,
    BookingPurchaseItemModel,
    IBookingPurchaseItemSnapshotIn,
    IBookingPurchaseItemSnapshotOut,
} from './bookingPurchaseItem';

import { BookingPurchaseListFormattedItem } from './types';

export const BookingPurchaseList = types
    .model('BookingPurchaseList', {
        list: types.array(BookingPurchaseItem),
        itemReference: types.maybeNull(types.reference(BookingPurchaseItem)),
    })
    .views((self) => {
        return {
            get isPurchaseItems(): boolean {
                return Boolean(self.list.length);
            },
            isPurchaseItemAlreadyInCart(id: string): boolean {
                return self.list.some((cartItem) => cartItem.itemId === id);
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetctPurchaseListDataByIds: flow(function* () {
                const itemIds = self.list.map((item) => item.itemId);

                if (Array.isArray(itemIds) && itemIds.length) {
                    const data: PurchaseListDto = yield httpClient.post(
                        'web-shop/checkouts/get-items',
                        {
                            itemIds,
                        }
                    );

                    const finalList = data.checkoutItems.map((item) => {
                        return {
                            ...item,
                            amount:
                                self.list.find(
                                    (itemInState) =>
                                        itemInState.id === item.id ||
                                        itemInState.itemId === item.id
                                )?.amount || item.amount,
                        };
                    });

                    applySnapshot(self, {
                        ...self,
                        list: finalList,
                        itemReference: null,
                    });
                }
            }),
        };
    })
    .actions((self) => {
        const {
            env: { storageService, httpClient },
        } = getEnv(self);
        return {
            updatePurchaseListInStorage(): void {
                storageService.setItem(storageKeys.purchaseItems, self.list);
            },
            updateUserPurchaseList: flow(function* () {
                const purchaseListFromStorage = JSON.parse(
                    storageService.getItem(storageKeys.purchaseItems)
                );
                if (
                    Array.isArray(purchaseListFromStorage) &&
                    purchaseListFromStorage.length
                ) {
                    yield httpClient.post('web-shop/items/checkouts', {
                        items: purchaseListFromStorage.map((item) => {
                            return {
                                itemId: item.itemId ?? item.id,
                                amount: item.amount,
                            };
                        }),
                    });
                    storageService.removeItem(storageKeys.purchaseItems);
                }
                storageService.removeItem(storageKeys.purchaseItems);
            }),
            fetchBookingPurchaseList: flow(function* () {
                const response: { items: { itemId: string; amount: number }[] } =
                    yield httpClient.get('web-shop/checkouts');
                const { items } = response;
                if (Array.isArray(items) && items.length) {
                    applySnapshot(self, {
                        ...self,
                        list: items.map((item) => ({
                            itemId: item.itemId,
                            amount: item.amount,
                        })),
                        itemReference: null,
                    });
                }
                self.fetctPurchaseListDataByIds();
            }),
        };
    })
    .actions((self) => {
        let initialState: IBookingPurchaseListSnapshotOut;

        const { authentication } = getRoot(self);
        const {
            env: { httpClient },
        } = getEnv(self);

        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },

            resetStore(): void {
                applySnapshot(self, initialState);
            },
            removeFromPurchaseList: flow(function* (id: string) {
                applySnapshot(self, {
                    ...self,
                    list: self.list.filter((cartItem) => cartItem.id !== id),
                    itemReference: null,
                });
                if (!authentication.isAuth) {
                    self.updatePurchaseListInStorage();
                } else {
                    yield httpClient.delete(`web-shop/items/${id}/checkouts`);
                }
            }),
            updatePurchaseListItemAmount: flow(function* (
                itemId: string,
                amount: number
            ) {
                yield httpClient.put(`web-shop/items/${itemId}/checkouts/update-amount`, {
                    amount: amount,
                });
            }),
            addToPurchaseList: flow(function* (item: IBookingPurchaseItemSnapshotIn) {
                const isPurchaseItemAlreadyInCart = self.isPurchaseItemAlreadyInCart(
                    item.id
                );
                if (!isPurchaseItemAlreadyInCart) {
                    applySnapshot(self, {
                        ...self,
                        itemReference: item.id,
                        list: [...self.list, { itemId: item.id, amount: item.amount }],
                    });
                    if (self.itemReference.id === item.id) {
                        self.itemReference = null;
                    }
                } else {
                    applySnapshot(self, {
                        ...self,
                        itemReference: null,
                        list: self.list.map((order) => {
                            if (order.id === item.id) {
                                return {
                                    ...order,
                                    amount: item.amount ?? 1,
                                };
                            }
                            return order;
                        }),
                    });
                }
                if (!authentication.isAuth) {
                    self.updatePurchaseListInStorage();
                } else {
                    yield httpClient.post(`web-shop/items/${item.id}/checkouts`, {
                        amount: item.amount ?? 1,
                    });
                }
                self.fetctPurchaseListDataByIds();
            }),
            processOrder: flow(function* () {
                const body = {
                    prices: self.list
                        .filter((item) => !item.reserved)
                        .map((item) => ({ amount: item.amount, itemId: item.itemId })),
                };
                const orderPaymentUrl = yield httpClient.post(
                    'web-shop/items/checkout/retail-customer',
                    body
                );
                window.location = orderPaymentUrl;
            }),
            continueOrder: flow(function* () {
                const orderPaymentUrl = yield httpClient.post(
                    'web-shop/retryOrderCheckout'
                );
                window.location = orderPaymentUrl;
            }),
            cancelOrder: flow(function* () {
                yield httpClient.post('web-shop/cancelOrder');
                self.fetctPurchaseListDataByIds();
            }),
            clearPurchaseList(): void {
                applySnapshot(self, { ...self, list: [], itemReference: null });
            },
            leaveOnlyReservedItems(): void {
                const reservedItems = self.list.filter((item) => item.reserved);
                applySnapshot(self, {
                    ...self,
                    list: reservedItems,
                    itemReference: null,
                });
            },
        };
    })
    .views((self) => {
        return {
            isItemInPurchaseList(id: string): boolean {
                return self.list.some((item) => item.itemId === id);
            },
            get amountOfPurchases(): number {
                return self.list?.length || 0;
            },
            get isReservedItemsBySomeoneInCart(): boolean {
                return self.list?.some((item) => item.reserved);
            },
            get isCompletePurchaseAvailable(): boolean {
                return Boolean(self.list?.filter((item) => !item.reserved).length);
            },
            get formattedPurchaseList(): BookingPurchaseListFormattedItem[] {
                const shops: Set<string> = new Set<string>(
                    self.list?.map((item) => item.shopId)
                );
                const formattedReservationList: BookingPurchaseListFormattedItem[] =
                    Array.from(shops).map((shop, index) => {
                        const itemsByShop = self.list?.filter(
                            (item) => item.shopId === shop
                        );
                        return {
                            id: `${index}`,
                            shop: itemsByShop[0].shopName,
                            shopId: itemsByShop[0].shopId,
                            location: itemsByShop[0].location,
                            workHours: itemsByShop[0].workhours,
                            shippingPrice: itemsByShop[0].shippingCosts,
                            freeShippingPrice: itemsByShop[0].freeOfShippingCosts,
                            items: itemsByShop.map(
                                (item: IBookingPurchaseItemSnapshotOut) => ({
                                    id: item.itemId,
                                    reserved: item.reserved,
                                    adddedToCart: false,
                                    availableForPurchaseAndBooking: false,
                                    addedToBookingList: false,
                                    localOnly: !item.shippingAvailable,
                                    isNewGood: item.isNewGood,
                                    shippingAvailable: item.shippingAvailable,
                                    name: item.name,
                                    price: item.price,
                                    freeShippingPrice: item.freeOfShippingCosts || 0,
                                    amount: item.amount,
                                    color: item.color,
                                    size: item.size,
                                    stock: item.stock,
                                    previewImage: item.previewImage,
                                    itemModel: item as BookingPurchaseItemModel,
                                    imageLink: generatePath(AppRoutes.goodsItem, {
                                        itemId: item.itemId || '',
                                    }),
                                })
                            ),
                        };
                    });
                return formattedReservationList;
            },
            get totalCartPrice(): number {
                const shops: Set<string> = new Set<string>(
                    self.list?.map((item) => item.shopId)
                );

                const totalShopShippingPrice = parseFloat(
                    Array.from(shops)
                        .reduce((acc, shopId) => {
                            const itemsPriceForDeliveryByShop = self.list
                                .filter(
                                    (item) =>
                                        item.shopId === shopId &&
                                        item.shippingAvailable &&
                                        !item.reserved
                                )
                                .reduce((acc, item) => acc + item.price * item.amount, 0)
                                .toFixed(2);
                            const shopInfo = self.list.find(
                                (item) => item.shopId === shopId
                            );
                            return (
                                acc +
                                (parseFloat(itemsPriceForDeliveryByShop) >=
                                    shopInfo?.freeOfShippingCosts ||
                                parseFloat(itemsPriceForDeliveryByShop) === 0
                                    ? 0
                                    : shopInfo?.shippingCosts)
                            );
                        }, 0)
                        .toFixed(2)
                );
                const totalItemsPrice = parseFloat(
                    self.list
                        .filter((item) => !item.reserved)
                        .reduce((acc, item) => acc + item.price * item.amount, 0)
                        .toFixed(2)
                );
                return totalShopShippingPrice + totalItemsPrice;
            },
        };
    });

export type BookingPurchaseListModel = Instance<typeof BookingPurchaseList>;
export type IBookingPurchaseListSnapshotOut = SnapshotOut<typeof BookingPurchaseList>;
